import { Asset } from '@/store/models/asset';
import { firebase } from '@/firebase';

/**
 * Returns a fake asset object used for hiding details for premium funds
 * @param {string} loc - The current locale
 */
export const premiumAssetTemplate = (loc: string = 'nl'): Omit<Asset, 'name' | 'city' | 'street' | 'country' | 'id' | 'startDateTime' | 'images' | 'investmentCase' | 'propertyDetails'> => ({
    aNotesSold: 0,
    totalNotesSold: 0,
    averageInterestRate: 0,
    durationLoans: '',
    nextInterestAmount: 0,
    nextInterestPayment: new firebase.firestore.Timestamp(23234, 0),
    returnYear: 0,
    sizeOfFund: 0,
    published: true,
    houseNumber: '',
    postalCode: '',
    euroMin: 0,
    totalValueEuro: 0,
    totalValueShares: 100,
    sharesAvailable: 100,
    totalDividendAmount: 0,
    initialAnnualInterestRate: 3,
    sharePrice: 0,
    emissionCost: 0,
    deleted: false,
    premium: false,
    brochure: [],
    dividendsFormat: [{ contents: ['3', 7.9] }, { contents: ['5', 10.8] }],
    createdDateTime: new firebase.firestore.Timestamp(23234, 0),
    endDateTime: new firebase.firestore.Timestamp(23234, 0),
    returnsAfterEnd: 0,
    floorPlanImages: [],
    prospectus: [],
    updatedDateTime: new firebase.firestore.Timestamp(23234, 0),
    totalCommitmentAmount: 20000,
    undrawnAmount: 2000,
  });
