import { Component, Prop, Vue } from 'vue-property-decorator';
import { Asset } from '@/store/models/asset';
import { dividendsReturnsOptions } from '@/components/common/net-value/dividendsReturnsOptions';

@Component({})

export default class NetValue extends Vue {
  @Prop() asset!: Asset;

  get dividendsReturnsOptions(): [string, number][] {
    return dividendsReturnsOptions(this.asset);
  }
}
